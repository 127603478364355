import {Route, Routes} from 'react-router-dom';
import './App.css';
import TopAppBar from './components/AppBar';
import {createTheme, ThemeProvider} from '@mui/material';
import {Suspense, lazy} from 'react';
import LoadingPage from './pages/LoadingPage';

const MainPage = lazy (() => import ('../src/pages/MainPage'));
const MembersPage = lazy (() => import ('../src/pages/MembersPage'));
const ProjectsPage = lazy (() => import ('../src/pages/ProjectsPage'));

function App () {
  const theme = createTheme ({
    typography: {
      fontFamily: ['Anta', 'Montserrat'].join (','),
      body1: {
        fontFamily: '"Montserrat", sans-serif',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Suspense fallback={<LoadingPage />}>
          <TopAppBar />
          <Routes>
            <Route path="/" element={<MainPage/>} />
            <Route path="/members" element={<MembersPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
          </Routes>
        </Suspense>
      </div>
    </ThemeProvider>
  );
}

export default App;
