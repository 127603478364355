import {AppBar, Container, Toolbar, Typography, Link} from '@mui/material';

const TopAppBar = () => {
  return (
    <AppBar
      position="sticky"
      sx={{backgroundColor: '#fffbff', boxShadow: 'none'}}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{padding: 1}}>
          <img
            src={require ('../assets/icon.png')}
            width={52}
            height={52}
            alt="icon"
          />
          <Link underline="none" href="/">
            <Typography
              variant="h5"
              sx={{paddingX: 3, color: 'black'}}
              fontWeight={900}
            >
              Overview
            </Typography>
          </Link>
          <Link underline="none" href="/members">
            <Typography variant="h6" sx={{color: 'black'}} fontWeight={500}>
              Members
            </Typography>
          </Link>
          <Link underline="none" href="/projects">
            <Typography
              variant="h6"
              sx={{paddingX: 3, color: 'black'}}
              fontWeight={500}
            >
              Projects
            </Typography>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopAppBar;
