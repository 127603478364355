import {Container, Typography} from '@mui/material';

const LoadingPage = () => {
  return (
    <Container
      sx={{
        height: '100vh',
        alignContent: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Typography variant="body1">Wait a sec!</Typography>
    </Container>
  );
};

export default LoadingPage;
